

:root {
  --shadow-color: 220 3% 15%;
  --shadow-strength: 1%;
  --font-weight-3: 300;
  --font-weight-5: 500;
  --size-4: 1.25rem;
  --size-6: 1.75rem;
  --size-3: 1rem;
  --gradient-23: linear-gradient(to bottom right,#536976,#292E49);
  --shadow-3: 
    0 -1px 3px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength) + 2%)),
    0 1px 2px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 2%)),
    0 2px 5px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 4%)),
    0 4px 12px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%)),
    0 12px 15px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 7%));
  --radius-2: 5px;
  --radius-3: 1rem;
  --border-size-1: 1px;
  --gray-6: #868e96;
  --gray-9: #212529;
  --green-4: #69db7c;
  --green-3: #8ce99a;
  --gray-2: #e9ecef;
  --orange-4: #ffa94d;
  --gray-3: #dee2e6;
  --violet-9: #5f3dc4;
  --cyan-4: #3bc9db;
  --gray-8: #343a40;
  --green-1: #d3f9d8;
  --green-2: #b2f2bb;
  --size-1: .25rem;
  --size-2: .5rem;
  --size-8: 3rem;
}

:root {
  --font-weight-3: 300;
  --font-weight-5: 500;
  --size-4: 1.25rem;
  --size-6: 1.75rem;
  /* color gradients */
  --gradient-23: linear-gradient(to bottom right, #536976, #292e49);
  --red-gradient: linear-gradient(
    rgba(185, 89, 115, 0),
    rgba(185, 89, 115, 0.7)
  );
  --yellow-gradient: linear-gradient(
    rgba(188, 144, 58, 0),
    rgba(188, 144, 58, 0.7)
  );
  --green-gradient: linear-gradient(
    rgba(72, 148, 120, 0),
    rgba(72, 148, 120, 0.7)
  );
  --blue-gradient: linear-gradient(
    rgba(101, 131, 209, 0),
    rgba(101, 131, 209, 0.7)
  );
  /* flower-filters */
  --magenta-filter: invert(18%) sepia(40%) saturate(3533%) hue-rotate(280deg)
    brightness(95%) contrast(91%);
  --red-filter: invert(50%) sepia(24%) saturate(1133%) hue-rotate(293deg)
    brightness(80%) contrast(85%);
  --yellow-filter: invert(57%) sepia(52%) saturate(546%) hue-rotate(1deg)
    brightness(93%) contrast(83%);
  --blue-filter: invert(63%) sepia(26%) saturate(6646%) hue-rotate(200deg)
    brightness(90%) contrast(81%);
  --green-filter: invert(53%) sepia(66%) saturate(287%) hue-rotate(106deg)
    brightness(84%) contrast(88%);
  --white-filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(167deg)
    brightness(113%) contrast(86%);

    /* default flower colour */
  --flower-filter: var(--magenta-filter);
    /* listener choice colors */
  --red-1: #cb315f;
  --yellow-1: #e0a91a;
  --blue-1: #3f66cb;
  --green-1: #40c866;
  --shadow-3: 0 -1px 3px 0 hsl(var(--shadow-color) /
          calc(var(--shadow-strength) + 2%)),
    0 1px 2px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 2%)),
    0 2px 5px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 4%)),
    0 4px 12px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 5%)),
    0 12px 15px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 7%));
  --violet-2: #d0bfff;
  --orange-4: #ffa94d;
  --violet-9: #5f3dc4;
  --cyan-4: #3bc9db;
  --cyan-9: #0b7285;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-6: #868e96;
  --gray-8: #343a40;
  --gray-9: #212529;
  --green-background: #1b2c06;
  /* dark theme colour palette, April 2023 */
  --green-1: #c1ffc0;
  --green-2: #dfeddf;
  --text-dark-1: #292d32;
  --text-white-1: #ededed;
  --slider-gray: rgba(217, 217, 217, 0.4);
  --input-window-gray: rgba(237, 237, 237, 0.2);
  /* end of dark theme colour palette */
  /* light theme colour palette, April 2023 */
  --light-background-surface: #f9ebe3;
  --green-3: #12674c;
  --green-4: #04371d;
  /* end of light theme colour palette */
  --radius-2: 5px;
  --radius-3: 1rem;
  --size-2: 0.5rem;
  --size-8: 3rem;
  --size-3: 1rem;
  --border-size-1: 1px;
  --shadow-color: 220 3% 15%;
  --shadow-strength: 1%;
}

:root {
  /* Typography */
  --font-weight-thin: var(--font-weight-3);
  --font-weight-thick: var(--font-weight-5);
  --font-size-large: 15.4px;
  --font-size-medium: 12px;
  --font-size-small: 10px;
  /* Font choices April 2023 */
  --primary-font: "WhyteInktrap", serif;
  --secondary-font: "Space Mono", monospace;
  --tertiary-font: "Inter", sans-serif;

  /* Padding and Spacing */
  --padding-comfy: var(--size-4);
  --p-line-height: var(--size-6);
  --padding-button: var(--size-3) var(--size-6);

  --bg-gradient-1: var(--gradient-23);
  --drop-shadow-1: var(--shadow-3);

  /* Border */
  --border-size-1: 1px;
  --border-radius-1: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-2));
  --border-radius-2: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-3));
  --border-thin: var(--border-size-1) solid var(--gray-6);
  --border-thin-active: var(--border-size-1) solid var(--surface-3);

  /* Light theme  */
  --surface-0-light: var(--light-background-surface);
  --surface-1-light: var(--gray-9);
  --surface-2-light: var(--green-4);
  --surface-3-light: var(--green-3);
  --surface-4-light: var(--gray-2);
  --surface-5-light: var(--orange-4);
  --surface-6-light: var(--gray-3);

  /* Dim theme */
  --surface-0-dim: var(--violet-9);
  --surface-1-dim: var(--gray-3);
  --surface-2-dim: var(--cyan-4);
  --surface-3-dim: var(--orange-4);
  --surface-4-dim: var(--gray-8);
  --surface-5-dim: var(--orange-4);
  --surface-6-dim: var(--gray-9);

  /* Dark theme */
  --surface-0-dark: var(--gray-9);
  --surface-1-dark: var(--text-white-1);
  --surface-2-dark: var(--green-1);
  --surface-3-dark: var(--green-2);
  --surface-4-dark: var(--slider-gray);
  --surface-5-dark: var(--orange-4);
  --surface-6-dark: var(--text-dark-1);

  /* Background */
  --surface-0: var(--green-background);
  /* Primary foreground (text) */
  --surface-1: var(--surface-1-dark);
  /* Button color */
  --surface-2: var(--surface-2-dark);
  /* Border color */
  --surface-3: var(--surface-3-dark);
  /* Input background */
  --surface-4: var(--surface-4-dark);
  /* Selected or progress color */
  --surface-5: var(--surface-5-dark);
  /* Secondary foreground (text) */
  --surface-6: var(--surface-6-dark);

  --font-size: var(--font-size-large);
}

[data-color="red"] {
  --gradient: var(--red-gradient);
  --flower-filter: var(--red-filter);
}

[data-color="blue"] {
  --gradient: var(--blue-gradient);
  --flower-filter: var(--blue-filter);
}

[data-color="green"] {
  --gradient: var(--green-gradient);
  --flower-filter: var(--green-filter);
}

[data-color="yellow"] {
  --gradient: var(--yellow-gradient);
  --flower-filter: var(--yellow-filter);
}

[data-theme="light"] {
  --surface-0: var(--surface-0-light);
  --surface-1: var(--surface-1-light);
  --surface-2: var(--surface-2-light);
  --surface-3: var(--surface-3-light);
  --surface-4: var(--surface-4-light);
  --surface-5: var(--surface-5-light);
  --surface-6: var(--surface-6-light);
}

[data-theme="dim"] {
  --surface-0: var(--surface-0-dim);
  --surface-1: var(--surface-1-dim);
  --surface-2: var(--surface-2-dim);
  --surface-3: var(--surface-3-dim);
  --surface-4: var(--surface-4-dim);
  --surface-5: var(--surface-5-dim);
  --surface-6: var(--surface-6-dim);
}

[data-theme="dark"] {
  --surface-0: var(--surface-0-dark);
  --surface-1: var(--surface-1-dark);
  --surface-2: var(--surface-2-dark);
  --surface-3: var(--surface-3-dark);
  --surface-4: var(--surface-4-dark);
  --surface-5: var(--surface-5-dark);
  --surface-6: var(--surface-6-dark);
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@font-face {
  font-family: "WhyteInktrap";
  src: local("WhyteInktrap"),
    url(/public/WhyteInktrap-Regular.woff) format("woff");
}

[data-font-size="font-size-large"] {
  font-size: calc(
    var(--font-size-large) + (26 - 14) * ((100vw - 320px) / (960 - 320))
  );
}

[data-font-size="font-size-medium"] {
  font-size: calc(
    var(--font-size-medium) + (26 - 14) * ((100vw - 320px) / (960 - 320))
  );
}

[data-font-size="font-size-small"] {
  font-size: calc(
    var(--font-size-small) + (26 - 14) * ((100vw - 320px) / (960 - 320))
  );
}

/* central flower filters */
.filter-magenta {
  filter: invert(18%) sepia(40%) saturate(3533%) hue-rotate(280deg)
    brightness(95%) contrast(91%);
}

.filter-orange {
  filter: invert(43%) sepia(47%) saturate(671%) hue-rotate(336deg)
    brightness(88%) contrast(86%);
}

h1,
h2,
h3 {
  font-weight: var(--font-weight-thin);
  color: var(--surface-1);
  font-family: var(--primary-font);
}

p,
span {
  color: var(--surface-1);
  font-weight: var(--font-weight-thin);
  font-family: var(--secondary-font);
}

p {
  padding: var(--padding-comfy);
  font-size: var(--font-size);
  line-height: var(--p-line-height);
  font-family: var(--primary-font);
}

.breath-countdown {
  font-size: 4rem;
}

html {
  overflow: hidden;
  /* height: 100%; */
  scroll-behavior: smooth;
}

@media screen and (max-width: 320px) {
  /* h1,
  label {
    font-size: calc(
      var(--font-size) + (26 - 14) * ((90vw - 320px) / (960 - 320))
    );
  } */
}

.dropdown-title {
  padding: var(--padding-comfy) 0;
  margin: 0 var(--size-3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid var(--surface-3);
}

.dropdown-title:last-child {
  border-bottom: 1px solid var(--surface-3);
}

.dropdown-title h3 {
  font-family: var(--secondary-font);
}

.dropdown-title p {
  padding: 0;
  font-size: 1.5rem;
}

.dropdown-title.clicked p {
  transform: rotate(45deg);
}

.dropdown-content {
  color: var(--surface-1);
  font-size: var(--font-size);
  font-family: var(--tertiary-font);
  text-align: left;
  border-top: 1px solid var(--surface-3);
  margin: 0 var(--size-3);
}

.dropdown-content p {
  font-family: var(--tertiary-font);
}

.dropdown-content a {
  background-color: var(--surface-3);
  padding: var(--size-1);
  text-decoration: none;
  border-radius: var(--size-2);
  font-family: var(--secondary-font);
  color: var(--surface-6);
}

progress,
progress[role] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 10px;
  width: 100%;
  border: none;
  border-radius: 40px;
}

progress::-moz-progress-bar {
  background: var(--surface-3);
  border: none;
  border-radius: 40px;
}

progress::-webkit-progress-value {
  background: var(--surface-3);
  border: none;
  border-radius: 40px;
}

progress::-webkit-progress-bar {
  border: none;
  border-radius: 40px;
}

button {
  padding: var(--padding-button);
  border: 0px solid var(--gray-6);
  border-radius: var(--radius-2);
  text-transform: uppercase;
  font-size: var(--font-size);
  font-weight: var(--font-weight-thick);
  margin: 0 auto;
  box-shadow: var(--drop-shadow-1);
  width: fit-content;
  background-color: var(--surface-3);
  color: var(--surface-6);
  font-family: var(--secondary-font);
}

.color-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: space-around;
  height: 100%;
}

.color-button {
  height: 7rem;
  width: 7rem;
  margin: 1rem;
  border-radius: 50%;
}

.color-button:focus {
  height: 7rem;
  width: 7rem;
}

select {
  width: 100%;
  height: var(--size-8);
  font-size: var(--font-size);
  text-align: center;
  border-radius: var(--radius-2);
  border: var(--border-thin);
  background-color: var(--surface-4);
  color: var(--surface-1);
}

input,
textarea {
  background-color: var(--input-window-gray);
  width: 100%;
  line-height: var(--size-8);
  border: none;
  text-align: center;
  font-size: var(--font-size);
  color: var(--surface-1);
  font-family: var(--secondary-font);
}

textarea {
  text-align: left;
  padding: 10px;
}

input:focus-visible {
  border: var(--border-thin);
  outline: none;
}

pre {
  color: var(--surface-2);
}

label {
  color: var(--surface-2);
}

.pill-img {
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
  border: solid 1px var(--surface-2);
  border-radius: 50px;
  font-family: var(--secondary-font);
  color: #c1ffc0;
  cursor: pointer;
  user-select: none;
  text-align: center;
  height: 80px;
  width: 80px;
}

.pill {
  border: solid 1px var(--surface-2);
  border-radius: 50px;
  display: flex;
  align-items: center;
  display: flex;
  font-family: var(--secondary-font);
  color: var(--surface-2);
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.pill-selected {
  background-color: var(--surface-3);
  color: var(--surface-0);
}

.font-pill-lettering {
  color: var(--surface-2);
}
.font-pill-selected {
  background-color: var(--surface-2);
}

.font-pill-selected p {
  color: black;
}

.pill-cluster {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-2);
  justify-content: center;
}

input[type="checkbox"] {
  width: var(--size-6);
  height: var(--size-6);
  vertical-align: middle;
  margin-right: 10px;
}

input[type="radio"] {
  /* make them invisible? */
  width: var(--size-6);
  opacity: 0;
  position: fixed;
}

input[type="checkbox"]::after {
  padding-left: 20px;
}

.code-input {
  background: rgba(62, 62, 62, 0.9);
  height: 250px;
  width: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--flower-filter);
  border-radius: 150px;
  font-size: 2em;
}

.wish-input {
  background: rgba(62, 62, 62, 0.1);
  width: 250px;
  padding: 25px;
  border-radius: 150px;
  font-size: var(--font-size);
}

.wish-input:focus-visible {
  outline: none;
}

.horizontal-labels {
  gap: 10%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  text-transform: uppercase;
}

.horizontal-labels p {
  font-family: var(--secondary-font);
  color: var(--surface-3);
}

.horizontal-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  text-align: center;
  padding: var(--size-2);
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: var(--surface-0);
  font-family: var(--primary-font);
  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  /* TODO: make a gradient change automatic, and generated from user choice */
  background: var(--gradient);
  background-color: var(--surface-0);
}

.fastTravel {
  width: 100%;
  height: var(--size-6);
}

.stack {
  display: flex;
  flex-direction: column;
  gap: var(--size-6);
  height: 100%;
}

.switcher {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.cluster {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.verticalCenter {
  width: 100%;
  gap: 10px;
  margin: auto 0;
}

.center {
  text-align: center;
}

.padding {
  padding: var(--padding-comfy);
}

.drawer {
  background-color: var(--surface-4) !important;
}

.audioLoading {
  color: var(--surface-1);
  background-color: var(--surface-0);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
}

.stepWrapper {
  height: 85vh;
  overflow-y: scroll;
}

.step {
  margin: var(--size-2);
  border: var(--border-thin);
  border-radius: var(--border-radius-1);
  background-color: var(--surface-0);
  color: var(--surface-1);
}

.step:hover {
  background-color: var(--surface-5);
}

.active-step {
  color: var(--surface-6);
  background-color: var(--surface-5);
}

.waveform path {
  stroke-width: 5px;
  stroke-linecap: round;
  fill: none;
  stroke-linejoin: round;
}

.tiles-wrapper {
  display: flex;
  justify-content: center;
  gap: var(--size-2);
  flex-wrap: wrap;
}

.tile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tile {
  border: var(--border-thin);
  background: var(--gradient-23);
  border-radius: var(--border-radius-2);
  box-shadow: var(--drop-shadow-1);
  height: 70px;
  width: 70px;
  display: flex;
  align-items: stretch;
  padding: var(--size-3);
  justify-content: center;
  align-items: center;
  color: var(--surface-1);
}

.tile > img {
  width: 90%;
  filter: grayscale(0.8);
  image-rendering: pixelated;
}

.tile > small {
  border: var(--border-thin);
  text-align: center;
}

.top-middle-bottom {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 25px 80% auto;
  grid-template-columns: 100%;
  overflow: hidden;
}

@media screen and (max-height: 454px) {
  .top-middle-bottom {
    height: 98%;
    grid-template-rows: 25px auto 19%;
  }
}

@media all and (orientation: landscape) {
  .top-middle-bottom {
    grid-template-rows: 25px 65% auto;
  }
}

.middle {
  overflow: auto;
  width: 100%;
}

.scroll-down-prompt {
  text-align: center;
  transform: "translate(0%, -70%)";
  width: "100px";
  background-image: var(--bg-gradient-1);
  padding: 3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  border-radius: var(--border-radius-1);
}

.jewel-video-container {
  background-color: black;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.jewel-progress-bar-container {
  display: none;
}

.jewel-video {
  height: 100%;
  width: 1005;
}

.star-container {
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.star-content {
  position: fixed;
  left: 0;
  top: 20%;
  width: 100%;
  padding: 20px;
}

#received-word {
  top: 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --shadow-color: 220 40% 2%;
    --shadow-strength: 25%;
  }
}

.shrink {
  animation: cssAnimation_0 6s ease-out;
}

.bounce {
  --animate-duration: 4s;
}

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: .75;
  }

  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  from,
  50%,
  to {
    opacity: .75;
  }

  25%,
  75% {
    opacity: 0;
  }
}
.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@keyframes cssAnimation_0 {
  to {
    transform: translate(0px, 0px) scale(0.25, 0.25);
  }
}

.grow {
  animation: cssAnimation_1 20s ease-out;
  animation-fill-mode: forwards;
}

.enter-animation {
  transform: translate(-200%, 0%) scale(0.1);
  animation: enterAnimation 10s ease-in-out forwards;
}

@keyframes enterAnimation {
  0% {
    transform: translate(-200%, 0%) scale(0.1); /* Start off-screen on the left and zoomed out */
    opacity: .1;
  }
  75% {
    transform: translate(0%, 0%) scale(0.1); /* Enter the screen, still small */
    opacity: .5;
  }
  100% {
    transform: translate(0, 0%) scale(1) rotate(360deg); /* Move to center and scale to full size */
    opacity: 1;
  }
}

.exit-animation {
  animation: exitAnimation 7s ease-in-out forwards;
  animation-delay: 45s;
}

@keyframes exitAnimation {
  0% {
    transform: translate(0%, 0%) scale(1) rotate(0deg); /* Start at full size in the center */
  }
  25% {
    transform: translate(0%, 0%) scale(0.1) rotate(360deg); /* Shrink and spin while still in the center */
  }
  100% {
    transform: translate(200%, 0%) scale(0.1); /* Move off-screen to the right, staying small */
  }
}

.fade {
  opacity: 0;
  animation: fade ease-out 5s;
  animation-fill-mode: forwards;
}

.received-word-text {
  opacity: 0;
}

#hello-flower-text {
  opacity: 0;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.25;
  } /* 20% of 5 seconds = 1 second */
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cssAnimation_1 {
  to {
    transform: translate(0px, 0px) scale(4, 4);
  }
}

.land-acknowledgement-1 {
  background-image: url(../public/assets/images/LandAknow_1_1080x1920.jpg);
  overflow-x: clip;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-size: cover;
  background-attachment: local;
}

.land-acknowledgement-1 h3 {
  color: var(--text-dark-1);
}

.land-acknowledgement-1 a {
  text-decoration: none;
}

.land-acknowledgement-2 {
  background-image: url(../public/assets/images/LandAknow_2_1080x1920.jpg);
  overflow-x: clip;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-size: cover;
  background-attachment: local;
}

.land-acknowledgement-2 .icon {
  filter: var(--white-filter);
}

.land-acknowledgement-2 h3 {
  color: var(--text-white-1);
}

.land-acknowledgement-2 h2 {
  color: var(--text-white-1);
}

.land-acknowledgement-1 h2 {
  color: var(--text-dark-1);
}

.land-acknowledgement-2 p {
  color: var(--text-white-1);
}

.land-acknowledgement-2 pre {
  color: var(--green-1);
}

.land-acknowledgement-2 .pill {
  border: solid 1px var(--green-1);
  color: var(--green-1);
}

.land-acknowledgement-2 .pill-selected {
  background-color: var(--green-2);
  color: var(--gray-9);
}

.spacer {
  padding: calc(10 * var(--padding-comfy));
}

.half-spacer {
  padding: calc(5 * var(--padding-comfy));
}

.flower-filter {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 325px;
  height: 325px;
}

.flower-filter::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url(../public/assets/images/remixed_flower.svg);
  filter: var(--flower-filter);
}

.flower-filter-inside {
  position: relative;
  text-align: center;
  top: 40%;
}

.flower-filter-input {
  position: relative;
  text-align: center;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  height: 12px;
  border-radius: var(--border-radius-1);
  background-color: var(--surface-4);
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  background: var(--surface-2);
  cursor: pointer;
  border-radius: var(--border-radius-1);
  border-radius: 50px;
}

.slider::-moz-range-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: var(--border-radius-1);
  background: var(--surface-2);
  cursor: pointer;
}

.flower-rotation {
  animation: flower-rotate 45s linear infinite;
}

@keyframes flower-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 100px;
  height: 48px;
  padding: 0;
  border-radius: 30px;
  background-color: var(--green-3);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--green-3);
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--green-2);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--green-2);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

/* react toggle */

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 44px;
  height: 44px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: var(--green-2);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 54px;
  border-color: #19ab27;
  background-color: var(--green-3);
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

#genre-select {
  display: none;
}

#mood-select {
  display: none;
}

.logo-image {
  width: 50%;
  height: 100%;
  object-fit: contain;
}

@media (prefers-color-scheme: dark) {

  :root {
    --shadow-color: 220 40% 2%;
    --shadow-strength: 25%;
  }
}
